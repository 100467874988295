
import {computed, defineComponent, ref} from 'vue'
import InvoiceSteps from '@/components/InvoiceSteps.vue'
import {store} from '@/store'
import {useRoute, useRouter} from 'vue-router'
import currency from '@/filters/currency'

export default defineComponent({
  name: 'InvoiceWireTransfer',
  components: {
    InvoiceSteps,
  },
  setup() {
    const route = useRoute()
    // const router = useRouter()
    const invoice = computed(() => store.state.invoice)
    // const isVerified = computed(() => store.state.verified)
    const showAccountTooltip = ref(false)
    const showRoutingTooltip = ref(false)

    // if (isVerified.value || (invoice.value?.contact?.kyc_verified)) {
    store.dispatch('getWireInfo', route.params.uuid)
    // } else {
    //   router.push({ name: 'InvoiceIdentityVerification' })
    // }

    return {
      currency,
      invoice,
      steps: computed(() => store.state.steps),
      accountNumber: computed(() => store.state.accountNumber),
      routingNumber: computed(() => store.state.accountNumber.length > 0 ? '021214891' : ''),
      isLoading: computed(() => store.state.accountNumber === ''),
      isPaid: computed(()=> store.state.invoice.paid_status === 'paid'),
      isPartiallyPaid: computed(()=> store.state.invoice.paid_status === 'partially paid'),
      showAccountTooltip,
      showRoutingTooltip,
    }
  },
  methods: {
    copyAccountNumber(): void {
      navigator.clipboard.writeText(this.accountNumber)
    },
    copyRoutingNumber():void{
      navigator.clipboard.writeText(this.routingNumber)
    }
  },
})
