<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ content ? `Arternal - ${content}` : `Arternal` }}
    </template>
  </metainfo>
  <suspense>
    <router-view />
  </suspense>
</template>

<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}
</style>
