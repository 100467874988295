
import { computed, defineComponent, onBeforeUnmount, ref, watch } from 'vue'
import { store } from '@/store'

export default defineComponent({
  name: 'InvoiceContactVerification',
  setup() {
    const scrolledToBottom = ref(false)
    const scrollDiv = ref<HTMLDivElement | null>(null)

    // Check for scroll to bottom
    const checkScroll = () => {
      if (!scrolledToBottom.value && scrollDiv.value) {
        scrolledToBottom.value =
          scrollDiv.value.offsetHeight + scrollDiv.value.scrollTop >=
          scrollDiv.value.scrollHeight
        if (scrolledToBottom.value)
          scrollDiv.value.removeEventListener('scroll', checkScroll)
      }
    }

    watch(scrollDiv, () => {
      scrollDiv.value?.addEventListener('scroll', checkScroll)
      checkScroll()
    })

    onBeforeUnmount(() => {
      scrollDiv.value?.removeEventListener('scroll', checkScroll)
    })

    return {
      invoice: computed(() => store.state.invoice),
      scrollDiv,
      scrolledToBottom,
      scrollHeight: scrollDiv.value?.offsetHeight,
    }
  },
})
