import { createWebHistory, createRouter, RouteRecordRaw } from 'vue-router'
import PageNotFound from '../pages/PageNotFound.vue'
import AffirmComplete from '../pages/AffirmComplete.vue'
import invoiceRouter from './modules/invoice'
import '../assets/css/theme.styl'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: PageNotFound,
  },

  invoiceRouter,

  { path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      savedPosition.behavior = 'smooth'
      return savedPosition
    }

    return { top: 0, behavior: 'smooth' }
  },
})

export default router
