
import { defineComponent, computed } from 'vue'
import { store } from '@/store'
import InvoiceSteps from '@/components/InvoiceSteps.vue'

export default defineComponent({
  name: 'InvoicePaymentStatus',
  setup() {
    return {
      invoice: computed(() => store.state.invoice),
      steps: computed(() => store.state.steps),
    }
  },
  components: {
    InvoiceSteps,
  },
})
