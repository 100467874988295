<template lang="pug">
.not-found
  i.mdi.mdi-emoticon-sad-outline.mb-3
  h1.pb-2 Invoice not found
  h5 Sorry, we cannot find that invoice.
</template>

<script>
export default {
  name: "InvoiceNotFound.vue"
}
</script>

<style scoped lang="stylus">
.not-found
  min-height 50vh
  padding 10vh 0

i
  color #999999
  font-size 3.5rem

h1
  font-size 24px

h5
  font-size 18px
</style>
