
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InvoiceSteps',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
    currentColour: {
      type: String,
    },
  },
  data() {
    return {
      progress: '0',
    }
  },
  created() {
    this.updateProgress()
  },
  mounted() {
    this.updateProgress()
  },
  watch: {
    step(old, newVal) {
      if (newVal !== old) {
        this.updateProgress()
      }
    },
  },
  methods: {
    updateProgress() {
      const circles = document.querySelectorAll('.circle')
      circles.forEach((circle: Element, idx: number) => {
        if (idx < this.currentStep) {
          circle.classList.add('active')
        } else {
          circle.classList.remove('active')
        }
      })

      const actives = document.querySelectorAll('.active')

      this.progress = ((actives.length - 1) / (circles.length - 1)) * 80 + '%'
      // console.log(`progressWidth: ${this.progress}`)
    },
  },
})
