import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
// import duration from 'dayjs/plugin/duration'
// import isBetween from 'dayjs/plugin/isBetween'
// import objectSupport from 'dayjs/plugin/objectSupport'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
// dayjs.extend(duration)
// dayjs.extend(isBetween)
// dayjs.extend(objectSupport)
dayjs.extend(advancedFormat)
dayjs.extend(localizedFormat)

export default dayjs
