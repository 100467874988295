
import { computed, defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import currency from '@/filters/currency'
import { store } from '@/store'

export default defineComponent({
  name: 'Invoice',
  setup() {
    useMeta({ title: 'Pay Invoice' })

    return {
      currency,
      invoice: computed(() => store.state.invoice),
      isPaid: computed(() => store.state.invoice.paid_status === 'paid'),
      isPartiallyPaid: computed(
        () => store.state.invoice.paid_status === 'partially paid'
      ),
      amountReceived: computed(
        () =>
          store.state.invoice.total_price -
          store.state.invoice.outstanding_amount
      ),
      affirmMethod: computed(
        () => store.state.invoice?.payment_method?.includes('affirm') ?? false
      ),
      wireMethod: computed(
        () => store.state.invoice?.payment_method?.includes('arternal') ?? false
      ),
    }
  },
  mounted() {
    if (this.invoice.payment_method.includes('affirm')) {
      this.checkout()
    } else if (this.invoice.payment_method.includes('arternal')) {
      this.$router.push({
        name: 'InvoiceWireInfo',
        params: { uuid: this.invoice.uuid },
      })
    }
  },
  methods: {
    addrValues(arr: string[]): string[] {
      return arr.filter((value) => {
        return value
      })
    },
    checkout() {
      const invoiceItems: {
        display_name: string
        sku: string
        unit_price: string
        qty: number
        item_image_url: string
      }[] = []
      this.invoice.items.forEach((item) => {
        const invoiceItem = {
          display_name: item.inventory.title,
          sku: item.inventory.id,
          unit_price: item.price,
          qty: 1,
          item_image_url: item.inventory.url,
        }
        invoiceItems.push(invoiceItem)
      })

      window?.affirm.ui.ready(function () {
        window?.affirm.ui.error.on('close', function () {
          alert('Please check your contact information for accuracy.')
        })
      })

      window?.affirm.checkout({
        merchant: {
          user_confirmation_url: `${process.env.VUE_APP_AFFIRM_AUTHORIZATION_ENDPOINT}/authorize/${this.invoice.uuid}`,
          user_cancel_url: `${window.location.origin}/invoice/${this.invoice.uuid}/cancelled`,
          user_confirmation_url_action: 'POST',
          name: 'Arternal',
        },
        shipping: {
          name: {
            first: this.invoice.contact.first_name,
            last: this.invoice.contact.last_name,
          },
          address: {
            line1: this.invoice.address_street,
            line2: '',
            city: this.invoice.address_city,
            state: this.invoice.address_state,
            zipcode: this.invoice.address_zip,
            country: this.invoice.address_country,
          },
          phone_number: this.invoice.contact.phone,
          email: this.invoice.contact.email,
        },
        billing: {
          name: {
            first: this.invoice.contact.first_name,
            last: this.invoice.contact.last_name,
          },
          address: {
            line1: this.invoice.address_street,
            line2: '',
            city: this.invoice.address_city,
            state: this.invoice.address_state,
            zipcode: this.invoice.address_zip,
            country: this.invoice.address_country,
          },
          phone_number: this.invoice.contact.phone,
          email: this.invoice.contact.email,
        },
        items: invoiceItems,
        order_id: this.invoice.uuid,
        currency: this.invoice.currency,
        shipping_amount: this.invoice.shipping_fee,
        tax_amount: this.invoice.tax_amount,
        total: this.invoice.total_price * 100,
      })

      window.affirm.checkout.open()
    },
  },
})
