import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5e3ec00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "steps" }
const _hoisted_2 = { class: "progress-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "progress",
        style: _normalizeStyle({ width: _ctx.progress })
      }, null, 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "step",
          key: index
        }, [
          (_ctx.currentColour && index + 1 === _ctx.currentStep)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "circle",
                style: _normalizeStyle({
            'background-color': _ctx.currentColour,
            'border-color': _ctx.currentColour,
          })
              }, null, 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["circle", {
            active: index < _ctx.currentStep,
            activated: index + 1 < _ctx.currentStep,
          }])
              }, null, 2)),
          _createElementVNode("span", null, _toDisplayString(step), 1)
        ]))
      }), 128))
    ])
  ]))
}