import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import router from '@/router'
import { store, key } from '@/store'
import '@/assets/index.css'
declare global {
  interface Window {
    affirm: any
  }
}
// prettier-ignore
createApp(App)
  .use(router)
  .use(store, key)
  .use(createMetaManager())
  .mount('#app')
