export default function (
    value: string | number,
    currency = 'USD',
    fraction = 0,
    displaySymbol: true,
): string {
    if (typeof value !== 'number') {
        value = parseFloat(value)
    }

    if (!currency) {
        currency = 'USD'
    }

    // https://www.btb.termiumplus.gc.ca/tpv2guides/guides/wrtps/index-eng.html?lang=eng&lettr=indx_catlog_a&page=9IYDvHKNiH6Q.html
    // Note that the dollar sign is redundant if used with the international code USD:
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        currencyDisplay: displaySymbol ? 'symbol' : 'code', // code or symbol
        minimumFractionDigits: fraction,
        maximumFractionDigits: 2,
    })

    return `${formatter.format(value ? value : 0)} ${currency}`
}
