
import {computed, defineComponent, ref} from 'vue'
import {store} from '@/store'
import InvoiceSteps from '@/components/InvoiceSteps.vue'
import currency from '@/filters/currency'
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'InvoicePartialPaymentPaid',
  setup() {
    const showTooltip = ref(false)
    const route = useRoute()
    store.dispatch('getWireInfo', route.params.uuid)

    return {
      isLoading: false,
      currency,
      invoice: computed(() => store.state.invoice),
      steps: computed(() => store.state.steps),
      accountNumber: computed(() => store.state.accountNumber),
      showTooltip,
    }
  },
  components: {
    InvoiceSteps,
  },
  methods: {
    copyAccountNumber(): void {
      navigator.clipboard.writeText(this.accountNumber)
    },
  },
})
