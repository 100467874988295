import { InjectionKey } from 'vue'
import { Commit, createStore, Store, useStore as baseUseStore } from 'vuex'
import InvoiceService from '@/_service/InvoiceService'
import dayjs from '@/utils/dayjs'

interface Invoice {
  id: bigint
  title: string
  uuid: string
  paid_status: string
  date_out: string
  items: Item[]
  currency: string
  organization: Organization
  contact: Contact
  shipped_contact: Contact
  total_price: number
  outstanding_amount: number
  download_url: string
  transaction_number: string
  discount: number
  tax_amount: number
  subtotal_price: number
  tax_shipping: boolean
  hide_subtotal: boolean
  shipping_fee: number
  payment_method: string

  first_name: string
  last_name: string
  address_street: string
  address_city: string
  address_state: string
  address_country: string
  address_zip: string

  shipped_street: string
  shipped_state: string
  shipped_city: string
  shipped_country: string
  shipped_zip: string
  shipped_email_key: string
}

interface Organization {
  id: bigint
  name: string
  logo_url: string
  image_url: string
  url: string
}

interface Contact {
  first_name: string
  last_name: string
  kyc_verified: boolean
  email: string
  primary_street: string
  primary_city: string
  primary_state: string
  primary_country: string
  primary_zip: string
  phone: string
}

interface Item {
  inventory: Inventory
  discount: bigint
  price: string
  currency: string
}

interface Inventory {
  artist: string
  description: string
  medium: string
  size_text: string
  url: string
  price: number
  price_currency: string
  catalog_number: string
  size_text_metric: string
  title: string
  id: string
}

export interface State {
  invoiceId: string
  logoUrl: string
  websiteUrl: string
  organization: string
  invoice: Invoice
  accountNumber: string
  steps: any
  verified: boolean
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  strict: true,

  state: {
    invoiceId: '',
    logoUrl: '',
    websiteUrl: '',
    organization: '',
    invoice: {} as Invoice,
    accountNumber: '',
    verified: false,
    steps: [
      // 'ID Verification',
      'Payment Details',
      'Processing',
      'Completed',
    ],
  },

  mutations: {
    setInvoice(state: State, invoice: Invoice): void {
      if (!invoice.currency) {
        invoice.currency = 'USD'
      }

      invoice.subtotal_price = invoice.subtotal_price / 100
      invoice.total_price = invoice.total_price / 100

      invoice.outstanding_amount =
        invoice.outstanding_amount > 0 ? invoice.outstanding_amount / 100 : 0

      invoice.items = invoice.items.map((item) => {
        if (item?.inventory && item.inventory.price) {
          item.inventory.price = Number(item.inventory.price) / 100
        }
        return item
      })

      state.invoice = invoice
      state.invoiceId = invoice.uuid
      state.logoUrl = invoice.organization?.image_url
      state.websiteUrl = invoice.organization?.url
      state.organization = invoice.organization?.name
      state.verified = invoice?.contact?.kyc_verified
    },
    setAccountNumber(state: State, accountNumber: string): void {
      state.accountNumber = accountNumber
    },
    setVerified(state: State, verified: boolean): void {
      state.verified = verified
      if (state.invoice && state.invoice.contact) {
        state.invoice.contact.kyc_verified = true
      }
    },
  },

  actions: {
    async getInvoice(
      { commit }: { commit: Commit },
      invoiceUuid: string
    ): Promise<void> {
      const { data: invoice } = await InvoiceService.getInvoice(invoiceUuid)
      commit('setInvoice', {
        ...invoice,
        date_out: dayjs(invoice?.date_out).format('MMMM D, YYYY'),
      })
    },
    getWireInfo({ commit }: { commit: Commit }, invoiceUuid: string): void {
      InvoiceService.getInvoiceWireTransferInfo(invoiceUuid).then((res) => {
        if (res.data.accountNumber) {
          commit('setAccountNumber', res.data.accountNumber)
        }
      })
    },
    setVerified({ commit }: { commit: Commit }, verified: boolean): void {
      commit('setVerified', verified)
    },
  },
})

// our own useStore to avoid having to import `useStore()` and `key` everywhere
export function useStore(): Store<State> {
  return baseUseStore(key)
}
