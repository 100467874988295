<template>
  <div class="container mx-auto px-5 py-16 text-center">
    <h1 class="mb-5 text-6xl font-bold">Page Not Found</h1>

    <p class="text-2xl font-bold">Sorry, there is no page at this address</p>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
}
</script>
