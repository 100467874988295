import { AxiosResponse } from 'axios'
import axios from '@/utils/axios'

export default class InvoiceService {

  public static getInvoice(uuid: string): Promise<AxiosResponse> {
    return axios.get(`invoice/${uuid}`)
  }

  public static getInvoiceWireTransferInfo(
    uuid: string
  ): Promise<AxiosResponse> {
    return axios.get(`invoice/${uuid}/wire`)
  }
}
