import Invoice from '../../pages/invoice/Invoice.vue'
import InvoiceLayout from '../../pages/invoice/InvoiceLayout.vue'
import InvoiceWireInfo from '@/pages/invoice/InvoiceWireInfo.vue'
import InvoicePaymentStatus from '@/pages/invoice/InvoicePaymentStatus.vue'
import InvoicePartialPaymentPaid from '@/pages/invoice/InvoicePartialPaymentPaid.vue'
import InvoiceCompleted from '@/pages/invoice/InvoiceCompleted.vue'
import InvoiceTerms from '@/pages/invoice/InvoiceTerms.vue'
import AffirmComplete from '@/pages/AffirmComplete.vue'
import AffirmCancelled from '@/pages/AffirmCancelled.vue'
import AffirmFailed from '@/pages/AffirmFailed.vue'

export default {
  path: '/invoice/:uuid',
  component: InvoiceLayout,
  props: true,
  children: [
    {
      path: '',
      component: Invoice,
      name: 'Invoice',
    },
    // {
    //   path: 'verification',
    //   component: InvoiceContactVerification,
    //   name: 'InvoiceContactVerification',
    // },
    {
      path: 'terms',
      component: InvoiceTerms,
      name: 'InvoiceTerms',
    },
    // {
    //   path: 'kyc',
    //   component: InvoiceIdentityVerification,
    //   name: 'InvoiceIdentityVerification',
    // },
    {
      path: 'wire',
      component: InvoiceWireInfo,
      name: 'InvoiceWireInfo',
    },
    {
      path: 'status',
      component: InvoicePaymentStatus,
      name: 'InvoicePaymentStatus',
    },
    {
      path: 'partially-paid',
      component: InvoicePartialPaymentPaid,
      name: 'InvoicePartialPaymentPaid',
    },
    {
      path: 'completed',
      component: InvoiceCompleted,
      name: 'InvoiceCompleted',
    },
    {
      path: 'affirm-completed',
      component: AffirmComplete,
      name: 'AffirmCompleted',
    },
    {
      path: 'cancelled',
      component: AffirmCancelled,
      name: 'AffirmCancelled',
    },
    {
      path: 'failed',
      component: AffirmFailed,
      name: 'AffirmCancelled',
    },
  ],
}
